import React from "react";
import { useTranslation } from "react-i18next";

function Main() {
  const { t } = useTranslation();
  return (
    <section className="main-bg flex items-center justify-center text-white py-20 w-[100%] bg-cover bg-no-repeat bg-center h-screen">
      <div className="container mx-auto p-5 text-center backdrop-blur-sm md:w-[1080px] w-[100%] backdrop-brightness-10 bg-[red]/30 rounded-sm">
        <h2 className="text-4xl font-bold mb-4">
          {t("main.title", { returnObjects: true })}
        </h2>
        <p className="text-xl">{t("main.descr", { returnObjects: true })}</p>
      </div>
    </section>
  );
}

export default Main;
