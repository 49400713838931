import React from 'react'
import Main from "./Main";
import Products from "./Products"
import About from "./About"
import Contacts from "./Contacts"

function Layout() {
  return (
    <>
    <Main />
    <Products />
    <About />
    <Contacts />
    </>
  )
}

export default Layout