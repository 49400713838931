import React, { useState, useEffect } from "react";
import { Menu, X } from "lucide-react";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";

function Header() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { t } = useTranslation();

  const headers = t("header.title", { returnObjects: true });
  const links = t("header.links", { returnObjects: true });

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("i18nextLng", lng);
  };
  const handleClick = (lng) => {
    changeLanguage(lng);
    setIsMenuOpen(false);
  }

  useEffect(() => {
    const savedLng = localStorage.getItem("i18nextLng");
    if (savedLng) {
      i18n.changeLanguage(savedLng);
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 30);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isMenuOpen]);

  

  return (
    <header
      className={`fixed top-0 left-0 w-full z-20 transition-colors duration-300 text-white p-4 ${
        isScrolled ? "bg-red-700" : "bg-transparent"
      }`}
    >
      <div className="container mx-auto flex justify-between items-center">
        <a href="/">
          <h1 className="text-3xl font-bold">Anor Auto</h1>
        </a>

        <nav className="hidden md:flex font-semibold">
          {headers.map((item, index) => (
            <a
              key={index}
              href={`#${links[index]}`}
              className="mx-2 hover:underline"
            >
              {item}
            </a>
          ))}
        </nav>

        <button
          className="md:hidden text-white focus:outline-none"
          onClick={toggleMenu}
        >
          <Menu />
        </button>

        <select
          className="hidden md:block px-4 py-2 border-none rounded text-white focus:border-none focus:outline-none bg-red-800 font-semibold"
          value={i18n.language}
          onChange={(e) => changeLanguage(e.target.value)}
        >
          <option value="en">English</option>
          <option value="ru">Русский</option>
          <option value="uz">O'zbek</option>
        </select>
      </div>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <div className="fixed inset-0 bg-red-700 flex flex-col items-center justify-center text-white text-2xl">
          <button
            className="absolute top-4 right-4 text-3xl"
            onClick={toggleMenu}
          >
            <X />
          </button>
          {headers.map((item, index) => (
            <a
              key={index}
              href={`#${links[index]}`}
              className="p-4 hover:underline font-semibold"
              onClick={() => setIsMenuOpen(false)}
            >
              {item}
            </a>
          ))}
          <div className="flex flex-col space-y-6">
            <button onClick={() => handleClick("en")} className="w-[300px] border p-4 border-red-800">English</button>
            <button onClick={() => handleClick("ru")} className="w-[300px] border p-4 border-red-800">Русский</button>
            <button onClick={() => handleClick("uz")} className="w-[300px] border p-4 border-red-800">O'zbek</button>
          </div>
        </div>
      )}
    </header>
  );
}

export default Header;
