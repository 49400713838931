import React from "react";
import { useTranslation } from 'react-i18next';

function About() {
  const { t } = useTranslation();

  return (
    <section
      id="about"
      className="bg-grey-500 pt-[50%]  md:py-16 h-screen flex items-center justify-center relative"
    >
      {/* Squares on the top left */}
      <div className="absolute md:top-0 md:left-0 hidden md:block w-[100px] h-[100px] bg-red-600 rounded-2xl rotate-45 "></div>
      <div className="absolute md:top-20 md:left-10 hidden md:block w-[100px] h-[100px] bg-red-800 rounded-2xl rotate-45 "></div>

      <div className="container mx-auto backdrop-blur-md md:w-[1080px] z-10 bg-black/70 text-white rounded-lg py-10">
        <h2 className="text-3xl font-bold mb-8 text-center ">
          {t("about.title", {returnObjects:true})}
        </h2>
        <p className="text-lg text-center max-w-3xl mx-auto text-white">
          {t("about.descr", {returnObjects:true})}
        </p>
      </div>

      {/* Sqaures on the bottom right */}
      <div className="z-0">
        <div className="absolute bottom-0 right-12 w-[200px] h-[200px] bg-red-600 rounded-2xl rotate-45"></div>
        <div className="absolute bottom-20 right-14 w-[200px] h-[200px] bg-red-800 rounded-2xl rotate-45"></div>
      </div>
    </section>
  );
}

export default About;
