import React from 'react'
import { useTranslation } from "react-i18next"

function Products() {

  const {t} = useTranslation()

  return (
    <section
          id="products"
          className="h-screen flex items-center justify-center "
        >
          <div className="container mx-auto sm:pt-[10%] pt-[40%]">
            <h2 className="text-3xl font-bold mb-8 text-center">
              {t("products.title")}
            </h2>
            <div className="grid lg:grid-cols-2 md:grid-cols-2 gap-8 z-100">
              {t("products._", {returnObjects: true}).map(
                (category, index) => (
                  <div
                    key={index}
                    className="bg-white p-6 rounded-lg shadow-md"
                  >
                    <h3 className="text-xl font-semibold mb-2">{category.title}</h3>
                    <p className="text-gray-600">
                      {category.descr}
                    </p>
                  </div>
                )
              )}
            </div>
          </div>
        </section>
  )
}

export default Products