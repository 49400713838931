import React from "react";
import { useTranslation } from "react-i18next";

function Footer() {
  
  const { t } = useTranslation()

  return (
    <footer className="bg-gray-800 text-white p-4">
      <div className="container mx-auto text-center">
        <p>&copy; {t("footer.title", {returnObjects: true} )}</p>
      </div>
    </footer>
  );
}

export default Footer;
