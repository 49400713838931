import { useEffect } from "react";
import "./App.css";
import Layout from "./components/Layout";
import Header from "./components/Header";
import Footer from "./components/Footer"
import i18n from './i18n'; 

function App() {
  
  useEffect(() => {
    const storedLanguage = localStorage.getItem('selectedLanguage');
    if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
    }
}, []);

  return (
    <div className="flex flex-col min-h-screen bg-gray-50">
     <Header />
      <main className="flex-grow">
      <Layout />
      </main>
      <Footer />
    </div>
  );
}

export default App;
