import React from "react";
import { Phone, Mail, MapPin, Instagram } from "lucide-react";
import { useTranslation } from "react-i18next";

function Contacts() {
  const { t } = useTranslation();
  return (
    <>
      <section className="py-16">
        <div className="container mx-auto">
          <h2 className="text-3xl font-bold mb-8 text-center">{t("brands", {returnObjects:true})}</h2>
          <div className="flex justify-around flex-wrap">
            {[
              "HOWO",
              "SHACMAN",
              "XCMG",
              "SHANTUI",
              "MAN",
              "KAMAZ",
              "FOTON",
              "MAZ",
              "CAMC",
              "DAF",
              "ZOOMLION"
            ].map((brand) => (
              <div
                key={brand}
                className="bg-white p-4 m-2 rounded shadow-lg flex items-center justify-between flex-col "
              >
                <img
                  src={require(`../../../assets/${brand.toLowerCase()}.png`)}
                  alt=""
                  className="w-[100px]"
                />
                <span className="font-semibold text-lg justify-start">
                  {brand}
                </span>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section
        id="contacts"
        className="bg-red-700 text-white py-16 md:flex md:flex-col"
      >
        <div className="container mx-auto md:flex md:flex-col">
          <h2 className="text-3xl font-bold mb-8 text-center">
            {t("contacts.title")}
          </h2>
          <div className="lg:flex lg:justify-center lg:space-x-12 xs:space-y-6 xs:space-x-3 ">
            <div className="flex items-center">
              <Phone className="w-6 h-6 mr-2 xs:ml-6 sm:ml-3" />
              <div className="md:w-[200px] xs:w-[400px] flex-col flex  md:space-x-0">
                <span>+998 (98) 360-88-84</span>
                <span>+998 (97) 774-29-29</span>
              </div>
            </div>
            <div className="flex items-center">
              <Mail className="w-6 h-6 mr-2 ml-3 sm:ml-0" />
              <span>{t("contacts.email")}: idealagroinvest@yandex.com</span>
            </div>
            <div className="flex items-center">
              <MapPin className="w-6 h-6 mr-2 ml-3 sm:ml-0" />
              <span>{t("contacts.place")}</span>
            </div>
            <div className="flex items-center">
              <a
                href="https://www.instagram.com/anor_auto/"
                className="flex items-center"
              >
                <Instagram className="w-6 h-6 mr-2 ml-3 sm:ml-0" />
                Instagram
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Contacts;
